import React, { useState, useEffect } from "react";
import { useAlert } from 'react-alert'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, CircularProgress } from '@mui/material';

import './App.css';


function App() {

  const alert = useAlert()

  const [backenddata, setBackenddata] = useState([]);
  const [input, setInput] = useState("");
  const [device, setDeviceId] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [otpValue, setOtpValue] = useState("");
  let [user, setUser] = useState("");


  let prod = 'https://api.kiot.io/noniot'
  // let prod = 'http://localhost:3002/noniot'
  // let prod = 'https://staging.kiot.io/noniot'


  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search);
    let deviceId = params.get('deviceId')
    setDeviceId(deviceId)
    if (!deviceId) {
      setStep(-1);
      setLoader(false)
      setBackenddata([])
      setMessage("Invalid Device Id")
      return
    }
    let url = `${prod}/checkWarranty?deviceId=${deviceId}`;

    fetchData(url);
  }, []);


  async function fetchData(url) {

    setLoader(true);
    const response = await fetch(url);
    const result = await response.json();
    if(result && result.create_warranty === true){
      setStep(3)
      setLoader(false)
      setBackenddata("")
    }
    else if (result && result.kiotdevice) {
        setBackenddata(result.kiotdevice.warranty);
        setLoader(false)
        setStep(0);
    }
    else {
      setStep(-1);
      setLoader(false)
      setBackenddata("")
      setMessage(result.message)
    }
  }

  async function submit() {
    setLoader(true);
    let url = `${prod}/createWarranty`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ deviceId: device, otp: otpValue })
    });
    const result = await response.json();

    console.log('final api result', result);
    if (result && result.message) {
      setLoader(false)
      alert.show(result.message)
    }
    else {
      setStep(2);
      setLoader(false)
      setBackenddata(result.warrantyObj);
    }
  }


  async function sendOtp() {
    setLoader(true);

    let url = `${prod}/sendOTP`;
    let query = { deviceId: device, user: input }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(query)
    });
    const result = await response.json();
    if (result && result.message) {
      setLoader(false)
      alert.show(result.message)
    }
    else {
     let str = input.toString()  
      if (str.length > 7) {
          let hiddenPart = str.substring(0, 7).replace(/./g, 'x');
          user = hiddenPart + str.substring(7);
      }
      setStep(1);
      setLoader(false)
      setUser(user)
    }
  }

  return (

    <>
      {step == -1 ?
        <div className="main">
          <PriorityHighIcon color="error" fontSize="large" className="icon" />
          <div className="heading">
         {message}
          </div>

        </div> : ""}

      {backenddata && (step != 1 || step == 2) && step != 3 && step != -1 ?

        (< div className="main">

          {loader == true ? <CircularProgress /> : ""}

          {
            step == 2 ?
              (<div className="heading">
                Your Warranty successfully created
              </div>) : <div className="heading">
                Your Warranty already exists
              </div>
          }

          <div>
            <CheckOutlinedIcon color="success" fontSize="large" className="icon" />
          </div>

          <div>
            Warranty started at {backenddata.warranty_start_date}
          </div>
          <br></br>
          <div>
            Warranty will be expired on {backenddata.expiry_date}
          </div>

        </div >
        ) :

        step == 1 ?

          < div className="main">

            <div className="heading">
              Create Warranty
            </div>

            <br></br>
            <FormControl>
              <FormLabel >Sent OTP to {user}</FormLabel>
              <br></br>

              <TextField type="text" value={otpValue} color='primary' label="Enter OTP" onChange={(e) => { setOtpValue(e.target.value) }} ></TextField>
              <Button className="mt-2rem" type="submit" onClick={() => { submit() }} variant="contained">Create</Button>
            </FormControl>

          </div>

          :
          step == 3 ?
            < div className="main">

              <div className="heading">
                Create Warranty
              </div>

              <br></br>
              <br></br>

              <FormControl >
                <TextField type="email" value={input} color='primary' label="Email Or Mobile Number" onChange={(e) => { setInput(e.target.value) }}></TextField>
                <Button className="mt-2rem" type="submit" onClick={() => { sendOtp() }} variant="contained">Next</Button>
                {loader == true ? <CircularProgress className="loader" /> : ""}
              </FormControl>

            </div>
            :
            ""

      }

    </>

  );
}

export default App;
